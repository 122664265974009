import * as ActionTypes from './ActionTypes';
import {SITES} from '../shared/sites';

export const addComment = (siteId, rating, author, text) => ({
    type: ActionTypes.ADD_COMMENT,
    payload: {
        siteId: siteId,
        rating: rating,
        author: author,
        text: text
    }
});
export const fetchSites = () => dispatch => {

    dispatch(sitesLoading());

    setTimeout(() => {
        dispatch(addSites(SITES));
    }, 2000);
};

export const sitesLoading = () => ({
    type: ActionTypes.SITES_LOADING
});

export const sitesFailed = errMess => ({
    type: ActionTypes.SITES_FAILED,
    payload: errMess
});

export const addSites = sites => ({
    type: ActionTypes.ADD_SITES,
    payload: sites
});