import React, { Component } from 'react';
import Product from './ProductComponent';
import SiteInfo from './SiteInfoComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import About from './AboutComponent';
import Contact from './ContactComponent';
import Recipes from './RecipesComponent';
import { Switch, Route, Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { addComment, fetchSites } from '../redux/ActionCreators';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const mapStateToProps = state => {
    return {
        sites: state.sites,
        comments: state.comments,
        partners: state.partners,
        markets: state.markets
    };
}
const mapDispatchToProps = {
    addComment: (siteId, rating, author, text) => (addComment(siteId, rating, author, text)),
    fetchSites: () => (fetchSites()),
    resetFeedbackForm: () => (actions.reset('feedbackForm'))
}
class Main extends Component {
    componentDidMount() {
        this.props.fetchSites();
    }
    render() {

        const AboutPage = () => {
            return (
                <About
                site={this.props.sites.sites.filter(site => site.featured)[0]}
                sitesLoading={this.props.sites.isLoading}
                sitesErrMess={this.props.sites.errMess}
                market={this.props.markets.filter(market => market.featured)[0]}
                partner={this.props.partners.filter(partner => partner.featured)[0]}
                />
            );
        };
        
        const SiteWithId = ({match}) => {
            return (
                <SiteInfo 
                site={this.props.sites.sites.filter(site => site.id === +match.params.siteId)[0]}
                isLoading={this.props.sites.isLoading}
                errMess={this.props.sites.errMess}
                comments={this.props.comments.filter(comment => comment.siteId === +match.params.siteId)}
                addComment={this.props.addComment}
                />
            );
        };

        return (
            <div>
                <Header />
                <TransitionGroup>
                    <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
                    <Switch>
                        <Route path='/about' component={AboutPage} />
                        <Route exact path='/product' render={() => <Product sites={this.props.sites} />} />
                        <Route path='/product/:siteId' component={SiteWithId} />
                        <Route exact path='/recipes' render={() => <Recipes partners={this.props.partners} /> } />
                        <Route exact path='/contact' render={() => <Contact resetFeedbackForm={this.props.resetFeedbackForm} />} />
                        <Redirect to='/about' />
                    </Switch>
                    </CSSTransition>
                </TransitionGroup>
                <Footer />
            </div>
        );
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));