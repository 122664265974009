export default {
    "success": true,
    "data": {
        "memes": [
            {
                "id": "181913649",
                "name": "Timiket",
                "url": "https://media.istockphoto.com/id/601363406/photo/african-woman-holding-basket-full-of-coffee-cherries-east-africa.jpg?b=1&s=170667a&w=0&k=20&c=jJcA4lyS32LCGUjd2CqibPI3FZF0i0eb_kNTcRcSCGA=",
                "width": 1200,
                "height": 1200,
                "box_count": 2
            },
            {
                "id": "112126428",
                "name": "Program",
                "url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ20moBCeJkq_dnnU8v7K-msWrd0xdmLmgR9g&usqp=CAU",
                "width": 1200,
                "height": 1200,
                "box_count": 3
            },
            
            {
                "id": "247375501",
                "name": "Buff Doge vs. Cheems",
                "url": "https://addisfortune.news/wp-content/uploads/2022/04/photo_2022-04-01_15-23-35.jpg",
                "width": 937,
                "height": 720,
                "box_count": 4
            },
            {
                "id": "129242436",
                "name": "Change My Mind",
                "url": "https://loveafricancoffee.com/wp-content/uploads/2020/09/ethiopian-coffee-roasting-1024x931.jpg",
                "width": 1200,
                "height": 1200,
                "box_count": 2
            },
            {
                "id": "181913649",
                "name": "Drake Hotline Bling",
                "url": "https://cdn.shopify.com/s/files/1/0002/1115/7052/products/ethiopian-yirgacheffe-washed-grade-1-coffee-100percent-arabica-fresh-roasted-coffee-beans-rhoadsroast-coffees-and-importers-7.jpg?v=1657562776&width=1445",
                "width": 1200,
                "height": 1200,
                "box_count": 2
            },
        ]
    }
}