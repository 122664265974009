import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Media } from 'reactstrap';
import { Link } from 'react-router-dom';

function RenderPartner({partner}) {
        if (partner) {
            return (
            <React.Fragment>
                <Media object src={partner.image} alt={partner.name}  width="150"/> 
                <Media body className="ml-5 mb-4">
                    <Media heading>{partner.name}</Media>
                    {partner.description}
                </Media>    
            </React.Fragment>  
            );
        } 
            return (
                <div></div>
            );
    }
function Recipes(props) {

    const partners = props.partners.map(partner => {
        return (
            <Media tag="li" key={partner.id}>
            <RenderPartner partner={partner}/>
            </Media>  
        );
    });

    return (
        <div className="container">
          <div className="row">
                <div className="col breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home" >About</Link></BreadcrumbItem>
                        <BreadcrumbItem active >Recipes</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row row-content">
                <div className="col-sm-6">
                    <h3>Delicious & Versatile In Recipes</h3>
                    <h6>Organic Coffee is low acidity and sweet-tasting coffee. Our coffees are roasted fresh to order, immediately packaged, nitrogen flushed, and shipped directly to your door. We want our customers to experience fresh coffee at peak drinkability, just a few days after being roasted to perfection.</h6>
                </div>
                <div className="col-sm-6">
                    <Card>
                        <CardHeader className="bg-secondary, text-white "><h3>What You’ll Need</h3></CardHeader>
                        <CardBody>
                            <dl className="row">
                                <dt className="col-6">coffee flour</dt>
                                <dd className="col-3">2 table spoons</dd>
                                <dt className="col-6">Water</dt>
                                <dd className="col-6">One cup</dd>
                                <dt className="col-6">Sugar</dt>
                                <dd className="col-6">to taste</dd>
                                <dt className="col-6">cardamom</dt>
                                <dd className="col-6">1 tablespoon </dd>
                            </dl>
                        </CardBody>
                    </Card>
                </div>
                <div className="col">
                    <Card className="bg-light mt-3">
                        <CardBody>
                            <blockquote className="blockquote">
                                <h4>I know once people get connected to real food, they never change back.</h4>
                                <footer className="blockquote-footer">Alice Waters,{' '}
                                    <cite title="Source Title">"Brainy Quote" -
                                    American-Chef, 2001-2022</cite>
                                </footer>
                            </blockquote>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row row-content1">
                <div className="col-12 ">
                    <h2>Types of Coffee Roasts</h2>
                    </div>
                <div className="col-12 ingera-serve1">
                    <Media list>
                        {partners}
                    </Media>
                    </div>
                    </div>
        </div>
    );
}

export default Recipes;