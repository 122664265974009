import React, { Component, Fragment } from 'react';
import { Card, CardImg, CardText, CardBody, Breadcrumb, BreadcrumbItem, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';


const maxLength = len => val => !val || (val.length <= len);
const minLength = len => val => val && (val.length >= len);
class CommentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }
    handleSubmit(values) {
        this.toggleModal();
        this.props.addComment(this.props.campsiteId, values.rating, values.author, values.text);
    }

    render() {
        return (
            <div>
                <Button outline onClick={this.toggleModal}><i className="fa fa-pencil"/>Submit Comment</Button>

                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Submit Comment</ModalHeader>
                    <ModalBody>
                        <LocalForm onSubmit={values => this.handleSubmit(values)} >
                            <div className="form-group">
                                <Label htmlFor="rating">Rating</Label>
                                <Control.select model=".rating" id='rating' name="rating" className='form-control' >
                                    <option >Select ..</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </Control.select>
                            </div>
                            <div className="form-group">
                                <Label htmlFor='author'>Your Name</Label>
                                <Control.text model=".author" id='author'
                                    placeholder='Your Name' className='form-control'
                                    validators={{
                                        minLength: minLength(2),
                                        maxLength: maxLength(15)
                                    }} />

                                    <Errors
                                    className="text-danger"
                                    model=".author"
                                    show="touched"
                                    component="div"
                                    messages={{
                                        minLength: 'Must be at least 2 characters',
                                        maxLength: 'Must be 15 characters or less'
                                    }}
                                /> 
                            </div>
                            <div className="form-group">
                                <Label htmlFor='text'>Comment</Label>
                                <Control.textarea model=".text" name='text' rows='6' id='text' className='form-control' ></Control.textarea>
                            </div>
                            <Button type="submit" value="submit" color="primary" >Submit</Button>
                        </LocalForm>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
function RenderSite({site}) {
        return (
            <div className="col-md-5 m-1">
                <Card>
                    <CardImg top src={site.image} alt={site.name} />
                    <CardBody>
                        <CardText>{site.description}</CardText>
                    </CardBody>
                </Card>
            </div>
        )
    }

    function RenderComments({comments, addComment, siteId}) {
        if (comments) {
            return (
                <div className="md-5">
                    <h4>Comments</h4>
                    {
                        comments.map(comment => {
                            return (
                                <div key={comment.id}>
                                    <p>
                                        {comment.text}
                                        <br />
                                        -- {comment.author}, {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(Date.parse(comment.date)))}
                                    </p> <br/>
                                </div>
                            );
                        })}
                        <CommentForm siteId ={siteId} addComment={addComment}/> 
                </div>
            );
        }
        return <div />;
    }
   
    function SiteInfo(props) {
        if (props.isLoading) {
            return (
                <div className="container">
                    <div className="row">
                        <Loading />
                    </div>
                </div>
            );
        }
        if (props.errMess) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4>{props.errMess}</h4>
                        </div>
                    </div>
                </div>
            );
        }
        if (props.site) {
            return (
                <div className="container product-1">
                    <div className="row breadcrumb">
                    <div className="col breadcrumb">{props.site.name}</div>
                            
                    </div>
                    <div className="row">
                        <div className="col breadcrumb">
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/product" >Product</Link></BreadcrumbItem>
                                <BreadcrumbItem active>{props.site.name}</BreadcrumbItem>
                            </Breadcrumb><hr />
                        </div>
                    </div>
                <div className="row">
                    <RenderSite site={props.site} />
                    <RenderComments 
                        comments={props.comments}
                        addComment={props.addComment}
                        siteId={props.site.id} />
                </div>
            </div>
            );
        }
        return <div />;
    }
    
    export default SiteInfo;