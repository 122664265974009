import React, { Component }  from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem, Jumbotron,Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label} from 'reactstrap';
import { NavLink } from 'react-router-dom';

class Header extends Component {
constructor(props) {
    super(props);
    
    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
            isNavOpen: false,
            isModalOpen: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

toggleNav() {
    this.setState({
        isNavOpen: !this.state.isNavOpen
    });
}
toggleModal() {
    this.setState({
        isModalOpen: !this.state.isModalOpen
    });
}
handleLogin(event) {
    alert(`Username: ${this.username.value} Password: ${this.password.value} Remember: ${this.remember.checked}`);
    this.toggleModal();
    event.preventDefault();
}

    render() {
        return (
            <React.Fragment>
            <div className=" container-fluid nav-cont">
            <Navbar light fixed="top" expand="lg" className="nav-cont">
                <div className="container">
                    <NavbarBrand className="mr-auto" href="/"><img src="/assets/images/logo1.png" height="40" width="100" alt="Ingera" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNav} />
                        <Collapse isOpen={this.state.isNavOpen} navbar>
                            <Nav navbar>
                                <NavItem>
                                    <NavLink className="nav-link" to="/about" >
                                        <i className="fa fa-home fa-md" /> About
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/product" >
                                        <i className="fa fa-list fa-md" /> Product
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/recipes" >
                                        <i className="fa fa-info fa-lg" /> Recipes
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/contact" >
                                        <i className="fa fa-address-card fa-md" /> Contact 
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <span className="navbar-text ml-auto">
                                <Button outline onClick={this.toggleModal}>
                                    <i className="fa fa-sign-in fa-md" /> <b>Login</b>
                                </Button>
                            </span>
                        </Collapse>
                    </div>
                </Navbar>
                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>Login</ModalHeader>
                <ModalBody>
                <Form onSubmit={this.handleLogin}>
                <FormGroup>
                    <Label htmlFor="username">Username</Label>
                    <Input type="text" id="username" name="username"
                        innerRef={input => this.username = input} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <Input type="password" id="password" name="password"
                        innerRef={input => this.password = input} />
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="remember"
                            innerRef={input => this.remember = input} />
                        Remember me
                    </Label>
                </FormGroup>
                <Button type="submit" value="submit" color="primary">Login</Button>
            </Form>
                </ModalBody>
            </Modal>
            </div>
            </React.Fragment>
        );
    }
}

export default Header;