export const PARTNERS = [
    {
        id: 0,
        name: "Light Roast ",
        image: "/assets/images/Light-roast.png",
        featured: false,
        description: "Light roasts are roasted for the least amount of time. Lightly roasted beans generally reach an internal temperature of 356°F – 401°F, right after the first crack occurs. These beans tend to not have the oils on them because they haven’t been roasted at a high enough temperature.The longer a bean is roasted the more the heat pulls out the caffeine and the acidity.The longer a bean is roasted the more the heat pulls out the caffeine and the acidity."
    },
    {
        id: 1,
        name: "Medium Roast ",
        image: "/assets/images/Medium-roast.png",
        featured: false,
        description: "Medium roasted coffee reaches internal temperatures of 410°F-428°F. This is after the first crack and just before the second one occurs. They have a little bit more body than a light roast and less acidity. Medium roasts are what the average American coffee drinker is used to. These roasts are considered to have balanced flavors. "
    },
    {
        id: 2,
        name: "Dark Roast",
        image: "/assets/images/Dark-roast.png",
        featured: false,
        description: "The roasting temperature for a dark roast is between 464°F – 482°F. There are visible oils on dark roast beans. Typically you cannot taste any origin flavors in a dark roast, just the effects the roasting process has on that type of coffee bean. Dark roasts have sweeter flavors because the sugars in the coffee beans have time to caramelize."
    }
    
]