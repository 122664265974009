import React from 'react';
import { Card, CardImg, CardImgOverlay, CardTitle,  Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';


    function RenderProductItem({site}) {
            return (
                <Card>
                    <Link to={`/product/${site.id}`}>
                    <CardImg width="100%" src={site.image} alt={site.name} />
                    <CardImgOverlay>
                        <CardTitle>{site.name}</CardTitle>
                    </CardImgOverlay>
                    </Link>
                </Card>
            );
        }
       
    function Product(props)  {
        const product = props.sites.sites.map(site => {
            return (
                <div key={site.id} className="col-md-5 m-1">
                <RenderProductItem site={site} />
                </div>
            );
        });
        if (props.sites.isLoading) {
            return (
                <div className="container">
                    <div className="row">
                        <Loading />
                    </div>
                </div>
            );
        }
        if (props.sites.errMess) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4>{props.sites.errMess}</h4>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="container product-1">
                <div className="row">
                    <div className="col breadcrumb">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/home" >About</Link></BreadcrumbItem>
                            <BreadcrumbItem active >Product</BreadcrumbItem>
                        </Breadcrumb> <br/>
                        
                    </div>
                </div>
                <div className="row">
                <h5 className="comment-1">Your comments would be greatly appreciated. Click pictures</h5>
                </div>
            <div className="row product-2">
            {product}
            </div>
        </div>
    );
}
export default Product;