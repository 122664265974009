import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <footer className="site-footer "><br/>
        <div className="container">
            <div className="row ">
                <div className="col-sm-6 ">
                    <img src="/assets/images/logo2.png" height="50" width="120" alt="Ingera" />
                </div>
                <div className="col-sm-6 footer-content ">
                    <a className="btn btn-social-icon btn-instagram" target="_blank" href="http://instagram.com/"><i className="fa fa-instagram" /></a>{' '}
                    <a className="btn btn-social-icon btn-facebook" target="_blank" href="http://www.facebook.com/"><i className="fa fa-facebook" /></a>{' '}
                    <a className="btn btn-social-icon btn-twitter" target="_blank" href="http://twitter.com/"><i className="fa fa-twitter" /></a>{' '}
                    <a className="btn btn-social-icon btn-google" target="_blank" href="http://youtube.com/"><i className="fa fa-youtube" /></a>
                </div><hr/>
                <div className="row ">
                <div class="col-12 site-footer1">
                    <a role="button" target="_blank"  href="https://github.com/ttewabe" style={{color:"white"}}><i className="fa fa-copyright fa-md" /><i>2020 | Designed & coded with 💙 by Tewabe T</i></a>
                </div>
                </div>
            </div>
        </div>
    </footer>
);
}
export default Footer;