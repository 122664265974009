import {createStore, combineReducers,applyMiddleware}from'redux';
import { createForms } from 'react-redux-form';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { InitialFeedback } from './forms';
import { Sites } from './sites';
import { Comments } from './comments';
import { Partners } from './partners';
import { Markets } from './markets';


export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            sites: Sites,
            comments: Comments,
            partners: Partners,
            markets: Markets,
            ...createForms({
                feedbackForm: InitialFeedback
            })
        }),
        applyMiddleware(thunk,logger)
    );

    return store;
};