import React from "react"
import memesData from "../memesData.js"

export default function About() {
    const [memeImage, setMemeImage]=React.useState("")

    function getMemeImage() {
        const memesArray = memesData.data.memes
        const randomNumber = Math.floor(Math.random() * memesArray.length)
        setMemeImage(memesArray[randomNumber].url)
        
    }
        return (
            <div className="container about-teff">
                <div className="row">
                    <div className="col breadcrumb">ABOUT  US</div>
                </div>
                <div className="row row-content">
                    <div className="col">
                        <h3>The Benefits os Organic Coffee</h3>
                        <h6 className="align-teff">Coffee Affection is organic, a safer, less toxic coffee bean. It has more micronutrients than regular coffee like magnesium, potassium, niacin, B vitamins, and minerals. This organic coffee distributer company has been supplying the Ethiopian and Eritrean communities for nearly one year with Ethiopian growing coffee bean. With fertile fields and ecologically-sensitive farming methods, some of the best quality coffee bean in the world is produced in Ethiopia.</h6>
                    </div>
                </div>
                <div class="row container1">
                <div class=" col-lg-3 box">
                    <div class="imgBx">
                        <img src="./assets/images/coffee-bean.jpeg"  alt="logo"/>
                    </div>
                    <div class="content">
                        <h2>Coffee Bean</h2>
                        <p>A coffee bean is the pip inside the red or purple fruit often referred to as a coffee cherry. Just like ordinary cherries.  </p>
                        <a target="_blank" href="https://www.technoserve.org/ethiopia-forest-coffee-illustrated-guide/#:~:text=Ethiopia's%20remaining%20coffee%20forests%20serve,coffees%20that%20grow%20wild%20there.">Read More</a>
                    </div>
                </div>
                <div class=" col-lg-3 box">
                    <div  class="imgBx">
                        <img src="./assets/images/coffee-process.jpeg"  alt="logo"/>
                    </div>
                    <div class="content">
                        <h2>Coffee Roasting</h2>
                        <p>Coffee beans are green and have almost no aroma. The roasting process is what makes coffee beans into the delicious.</p>
                        <a  target="_blank" href="https://perfectdailygrind.com/2019/11/ethiopian-coffee-a-roasters-guide/">Read More</a>
                    </div>
                </div>
                <div class=" col-lg-3 box">
                    <div class="imgBx">
                        <img src="./assets/images/affection1.jpeg"  alt="logo"/>
                    </div>
                    <div class="content">
                        <h2>Coffee Affection Package </h2>
                        <p>Coffee bean gets roasted and ground with a mortar and pestle and then packed in the packages.</p>
                        <a target="_blank" href="https://perfectdailygrind.com/2019/11/ethiopian-coffee-a-roasters-guide/">Read More</a>
                    </div>
                </div>
                <div class="box col-lg-3">
                    <div class="imgBx">
                        <img src="./assets/images/coffee-affection.jpeg"  alt="logo"/>
                    </div>
                    <div class="content">
                        <h2>Coffee ceremony</h2>
                        <p>Invitation to attend a coffee ceremony is considered a mark of friendship or respect.</p>
                        <a target="_blank" href="https://culturallyours.com/2019/10/12/traditional-ethiopian-coffee-ceremony/">Read More</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div className="form">
                    <button className="form--button" onClick={getMemeImage}>Get a new meme image</button>
                </div>
                    <img src={memeImage} className ="meme--image" />
            </div>
            </div>
        );
    }
