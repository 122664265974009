export const COMMENTS = [
    {
        id: 0,
        siteId: 3,
        rating: 5, 
        text: "Ingera is Organic and testy .I've always wanted to try it out. Gonna go soon after I've seen this delicious spread!!",
        author: "David Taye",
        date: "2022-06-13T16:30Z"
    }
]



